import { APP_URL } from "@/constants";
import { IBreadcrumbs } from "@/types";
import { generateJsonLd } from "@/utils";
import { useTranslation } from "next-i18next";

export default function BreadcrumbListJsonLd({
  breadcrumbs,
}: {
  breadcrumbs: IBreadcrumbs[];
}) {
  const { i18n } = useTranslation();
  const jsonLdBreadcrumbList = generateJsonLd("BreadcrumbList", i18n.language, {
    itemListElement: breadcrumbs.map((area, index) => ({
      "@type": "ListItem",
      position: index + 1,
      name: area.title,
      item: APP_URL + area.href,
    })),
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdBreadcrumbList),
      }}
    />
  );
}
