import { APP_URL } from "@/constants";
import { generateJsonLd, getCurrentLocaleUrl } from "@/utils";
import { useTranslation } from "next-i18next";

export default function WebpageJsonLd({
  name,
  description,
  pathname,
}: {
  name: string;
  description: string;
  pathname: string;
}) {
  const { i18n } = useTranslation();
  const currentLocale = getCurrentLocaleUrl(i18n.language);
  const jsonLdWebPage = generateJsonLd("WebPage", i18n.language, {
    url: `${APP_URL}${currentLocale}${pathname}`,
    name: name,
    description: description,
  });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(jsonLdWebPage),
      }}
    />
  );
}
